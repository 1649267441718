body {
  min-height: 100vh;
  margin: 0;
  background: linear-gradient(180deg,#F8D4C2 0%,#FEF2E4 10.45%,#FFFFFF 41.35%);
  background-color: #F8D4C2;
}

a {
  text-decoration: none;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
