@font-face {
  font-family: 'Poppins-Regular';
  src: url(../fonts/poppins-regular-webfont.woff) format("woff2")
}

@font-face {
  font-family: 'Poppins-Medium';
  src: url(../fonts/poppins-medium-webfont.woff) format("woff2")
}

@font-face {
  font-family: 'Poppins-Bold';
  src: url(../fonts/poppins-bold-webfont.woff) format("woff2")
}


@font-face {
  font-family: 'Poppins-Light';
  src: url(../fonts/poppins-light-webfont.woff) format("woff2")
}

@font-face {
  font-family: 'Poppins-Semibold';
  src: url(../fonts/poppins-semibold-webfont.woff) format("woff2")
}

